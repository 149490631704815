import React, { useEffect, useState, useMemo } from 'react';
import { useReactiveVar, useQuery } from '@apollo/client';

import { updateMotorist, updateDictionary, selectedLang } from '../reactiveVariables';
import GET_DICTIONARY from '../GQL/dictionary';

import dictionaryUX from './defaultUXDictionary';

export const useTraductor = () => {
  const { loading, error, data } = useQuery(GET_DICTIONARY);

  useEffect(() => {
    if (data?.i18n && !loading) {
      console.log('update dictionary');
      updateDictionary(
        data?.i18n?.documents.reduce(
          (acc, { lang, translations }) => ({
            ...acc,
            //LOL [lang]: {  ...translations,...dictionaryUX[lang] } POURQUOI
            [lang]: { ...dictionaryUX[lang], ...translations },
          }),
          {}
        ) ?? {}
      );
    }
  }, [data]);

  return {
    isCharged: !!data?.i18n,
  };
};

export const useLangSelector = () => {
  const lang = useReactiveVar(selectedLang);
  const dictionaries = useReactiveVar(updateDictionary);
  const languages = useMemo(() => Object.keys(dictionaries), [dictionaries]);

  const setLang = (newLang, sub) => {
    if (lang !== newLang) {
      console.log(`select lang ${lang} --> ${newLang} (${sub ?? 'select'})`);
      selectedLang(newLang);
    }
  };

  return {
    setLang,
    lang,
    languages,
  };
};

export const useDictionaries = () => {
  const dictionaries = useReactiveVar(updateDictionary);
  const lang = useReactiveVar(selectedLang);

  const selectedDictionaries = dictionaries[lang] ?? dictionaries?.en_EN ?? { en_EN: {} };
  const text = (key) =>
    `${selectedDictionaries[key] ?? `#${dictionaries?.en_EN[key] ?? `#${key}`}`}`;
  return text;
};
