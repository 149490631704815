import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useSessionStorageString } from 'react-use-window-sessionstorage';
import Layout from '../../layout';
import fondPhoto from './fond.jpg'; // Tell webpack this JS file uses this image
import SIGNING from '../../GQL/signin.gql';
import { useDictionaries } from '../../hooks/useTraductor';

import './style.css';

export default function Home() {
  const text = useDictionaries();

  const [login, { data, loading, error }] = useMutation(SIGNING);
  const [token, setToken] = useSessionStorageString('session-token', '');
  const [states, setStates] = useState({ username: '', password: '' });

  const handleChangeEmail = (evt) => {
    setStates({ ...states, username: evt.target.value });
  };

  const handleChangePassword = (evt) => {
    setStates({ ...states, password: evt.target.value });
  };

  const handleClickLogin = () => {
    if (states.username && states.password) {
      login({ variables: { ...states } });
    }
  };

  useEffect(() => {
    if (data) {
      setToken(data?.login?.token ?? '');
    }
  }, [data]);

  return (
    <Layout>
      <div className="home" />
      <div className="homeContainer">
        <div className="titleContainer">
          <div>
            <h1 className="title">OpenSwissParking</h1>
            <h2 className="subTitle">{text('landing-page-sub-title')}</h2>
          </div>
          <div className="describeContainer">
            <p className="describe">{text('landing-page-pub-text')}</p>
          </div>
        </div>
        <div className="loginFormContainer">
          <div className="loginFormBox">
            <form className="form" autoComplete="on">
              <p className="label">{text('landing-page-loginForm-label-email')}</p>
              <input
                className="loginInput"
                id="username"
                autoComplete="username"
                type="email"
                onChange={handleChangeEmail}
              />
              <div style={{ height: '8px' }} />
              <p className="label">{text('landing-page-loginForm-label-password')}</p>
              <input
                className="loginInput"
                id="password"
                autoComplete="current-password"
                type="password"
                onChange={handleChangePassword}
              />
              {error ? <p style={{ color: '#C81400' }}>Email or password invalid</p> : <p></p>}
            </form>
            <div className="divButtom">
              <button type="button" className="loginButton" onClick={handleClickLogin}>
                {text('landing-page-loginForm-loginButton')}
              </button>
            </div>
          </div>
          <div className="bottomFormLogin">
            <span>{text('landing-page-contactForm-infoText')}</span>
            <div className="divButtom">
              <button type="button" className="contactUsButtonLogin">
                {text('landing-page-contactForm-contact')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
