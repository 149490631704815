import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { useSessionStorageString } from 'react-use-window-sessionstorage';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import reportWebVitals from './reportWebVitals';
import { GRAPHQL_HOST, BFF_AUTH_TOKEN } from './common/config/config';
import Main from './Main';

// Create Apollo client
const httpLink = createHttpLink({ uri: GRAPHQL_HOST });

const useStart = () => {
  const [client, setClient] = useState();
  const [token] = useSessionStorageString('session-token', '');

  useEffect(() => {
    // Inject authentification bearer if enabled
    const authLink = setContext(async (_, { headers }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${BFF_AUTH_TOKEN}`,
        ...(token !== '' ? { 'x-access-token': `${token}` } : {}),
      },
    }));

    const defaultOptions = {
      // query: {
      //   fetchPolicy: 'no-cache',
      //   errorPolicy: 'all',
      // },
    };

    setClient(
      new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
        defaultOptions,
      })
    );
  }, [token]);

  return { client };
};

function App() {
  const { client } = useStart();
  if (!client) return <div>Loading...</div>;

  console.log('GRAPHQL_HOST', GRAPHQL_HOST);

  return (
    <ApolloProvider client={client}>
      <Main />
    </ApolloProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
