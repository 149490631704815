import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime, Duration as LDuration } from 'luxon';

// Convert seconds into an understandable format
function secondsToHms(d) {
  const dur = LDuration.fromObject({ seconds: d });
  const { days, hours, minutes, seconds } = dur
    .shiftTo('days', 'hours', 'minutes', 'seconds')
    .toObject();
  if (d < 3600) return `${minutes}m ${Math.round(seconds)}s`;
  return `${days}d ${hours}h ${minutes}m`;
}

export const Duration = ({ startDate, endDate, duration }) => {
  // Check if we still inside and format
  const formatedCounter = useMemo(() => {
    // We have a duration, just show it
    if (duration) return secondsToHms(duration);

    const transformedStartDate = DateTime.fromISO(startDate);

    // No end date, just show a counter from startDate to now
    if (!endDate) {
      const seconds = transformedStartDate.diffNow('seconds').toObject().seconds;

      return secondsToHms(Math.abs(seconds));
    }

    // We have a startDate and an endDate, show diff between
    const transformedEndDate = DateTime.fromISO(endDate);
    const seconds = transformedEndDate.diff(transformedStartDate, 'seconds').toObject().seconds;

    return secondsToHms(Math.abs(seconds));
  }, [startDate, endDate, duration]);

  return <span>{formatedCounter}</span>;
};

Duration.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  duration: PropTypes.number,
};
