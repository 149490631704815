import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import { useSessionStorageString } from 'react-use-window-sessionstorage';
import Layout from '../../layout';

import './style.css';

export default function Home() {
  const [token, setToken] = useSessionStorageString('session-token', '');

  const [states, setStates] = useState({ username: '', password: '' });

  const handleChangeEmail = (evt) => {
    setStates({ ...states, username: evt.target.value });
  };

  const handleChangePassword = (evt) => {
    setStates({ ...states, password: evt.target.value });
  };

  useEffect(() => {
    setToken('');
  }, []);

  return (
    <Layout>
      <div style={{ display: 'flex', justifyContent: 'center', width: '90%', height: '50%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <div>
            <h1 className="title">Welcome to OpenSwissParking</h1>
          </div>
          <div className="messageContainer">
            <h2 className="subTitleConfirmation">Your registration was successful!</h2>
            <h2 className="subTitleConfirmation">Thank you for joining OpenSwissParking</h2>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '50%',
            height: '100%',
          }}
        >
          <div
            style={{
              height: '100px',
              backgroundColor: '#d6d1d1',
              color: 'white',
              width: '500px',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <span>Discover your Motorist Platform now</span>
            <div
              style={{
                width: '40%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Link
                to="/"
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <button type="button" className="contactUsButton">
                  Enter
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
