const dictionary = {
  en_EN: {
    'header-right-logout-button': 'logout',
    'landing-page-sub-title': 'motorist',
    'landing-page-pub-text': 'Discover the advantages of the OpenSwissParking platform!',
    'account-alert-message-duplicate-cards': 'Duplicate cards found',
    'account-alert-message-duplicate-plates': 'Duplicate plates found',
    'landing-page-loginForm-loginButton': 'login',
    'landing-page-loginForm-label-email': 'email',
    'landing-page-loginForm-label-password': 'password',
    'landing-page-contactForm-contact': 'Contact us',
    'landing-page-contactForm-infoText': "don't have an acount?",
    'dashboard-home-tree-account-title': 'my account',
    'dashboard-home-tree-account-set-and-actualize': 'set and actualize',
    'dashboard-home-tree-account-personal-informations': 'personal informations',
    'dashboard-home-tree-account-password': 'password',
    'dashboard-home-tree-account-phone-numbers': 'phone numbers',
    'dashboard-home-tree-contracts-title': 'my contracts',
    'dashboard-home-tree-contracts-see-contracts': 'see contracts',
    'dashboard-home-tree-movements-title': 'movements',
    'dashboard-home-tree-movements-see-history': 'see transaction history',
    'dashboard-home-tree-movements-search': 'search specific transactions',
    'account-page-sub-title': 'Personal Data',
    'account-page-form-label-username': 'username',
    'account-page-form-label-lang': 'lang',
    'account-page-form-label-gender': 'gender',
    'account-page-form-label-firstName': 'firstName',
    'account-page-form-label-lastName': 'lastName',
    'account-page-form-label-address': 'address',
    'account-page-form-label-zipcode': 'zipcode',
    'account-page-form-label-address-complement': 'address complement',
    'account-page-form-label-city': 'city',
    'account-page-form-label-card': 'card',
    'account-page-form-label-card-type': 'type',
    'account-page-form-label-card-number': 'number',
    'account-page-form-label-card-add': 'add',
    'account-page-form-label-vehicles': 'vehicles',
    'account-page-form-label-vehicles-plate': 'Licence plate',
    'account-page-form-label-vehicles-description': 'description',
    'account-page-form-label-vehicles-add': 'add',
    'account-page-form-submitZone-edit': 'edit personal data',
    'account-page-form-submitZone-confirm': 'confirm',
    'account-page-form-submitZone-cancel': 'cancel',
    'account-page-form-label-phone': 'phone',
    'account-page-form-label-country': 'country',
    'account-page-form-option-male': 'male',
    'account-page-form-option-female': 'female',
    'account-page-form-option-unknown': 'unknown',
    'account-alert-title-rejected-credentials': 'Credentials rejected',
    'account-alert-message-rejected-credentials':
      'At least one of the credentials you tried to enter was rejected, it may not be valid or it may be used by another user.',
    'contracts-page-sect1-title': 'all contracts',
    'contracts-page-table-label-parkingName': 'location',
    'contracts-page-table-label-id': 'contract N°',
    'contracts-page-table-label-startDate': 'valid from',
    'contracts-page-table-label-stopDate': 'valid to',
    'contracts-page-table-label-isEnabled': 'status',
    'contracts-page-table-label-detail': 'details',
    'contracts-page-table-label-isEnabled-status-active': 'active',
    'contracts-page-table-label-isEnabled-status-inactive': 'inactive',
    'contracts-page-table-label-isEnabled-status-expired': 'expired',
    'contract-page-backButton-to-contracts': 'contracts',
    'contract-page-label-contract': 'contract',
    'contract-page-label-startDate': 'valid from',
    'contract-page-label-stopDate': 'valid to',
    'contract-page-label-status': 'status',
    'contract-page-form-label-vehicles': 'vehicles',
    'contract-page-form-label-vehicles-plate': 'Licence plate',
    'contract-page-form-label-vehicles-description': 'description',
    'contract-page-form-label-cards': 'cards',
    'contract-page-form-label-cards-type': 'licence plate',
    'contract-page-form-label-cards-number': 'number',
    'contract-page-label-contract-nb': 'contract number',
    'movements-page-sect1-title': 'all movements',
    'movement-page-table-label-entry': 'entry',
    'movement-page-table-label-exit': 'exit',
    'movement-page-table-label-duration': 'duration',
    'movement-page-table-label-fee': 'fee',
    'movement-page-table-label-detail': 'detail',
    'movement-page-table-label-localisation': 'localisation',
    'session-page-backButton-to-movement': 'movements',
    'session-page-label-session': 'session',
    'session-page-label-entryDate': 'entry',
    'session-page-label-stopDate': 'exit',
    'session-page-label-duration': 'duration',
    'session-page-label-fee': 'fee',
    'session-page-label-licencePlate': 'licence plate no',
    'session-page-label-description': 'description',
    'session-page-label-paymentMode': 'payment mode',
    'session-page-label-contractId': 'contract no',
    'session-page-label-contractFrom': 'valid from',
    'session-page-label-contractTo': 'valid to',
  },
  fr_FR: {
    'header-right-logout-button': 'déconnexion',
    'landing-page-pub-text': 'Découvrez les avantages de la plateforme OpenSwissParking !',
    'landing-page-loginForm-loginButton': 'connexion',
  },
  it_IT: {
    'header-right-logout-button': 'disconnessione',
    'landing-page-pub-text': 'Scoprite i vantaggi della piattaforma OpenSwissParking!',
    'landing-page-loginForm-loginButton': 'accesso',
  },
  de_DE: {
    'header-right-logout-button': 'Unterbrechung',
    'landing-page-pub-text': 'Entdecken Sie die Vorteile der OpenSwissParking-Plattform!',
    'landing-page-loginForm-loginButton': 'anmeldung',
  },
};

export default dictionary;
