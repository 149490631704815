import React from 'react';
import Layout from '../../layout';

export default function Home() {
  return (
    <Layout>
      <div>(404) page not found !!!</div>
    </Layout>
  );
}
