import React from 'react';
import { useNavigate } from 'react-router-dom';
import { isWithinInterval, isAfter, format } from 'date-fns';

import { GqlPagination, Field } from '../../legacy_components/GqlPagination';
import { QUERY_CONTRACTS } from '../../GQL/contract';
import Layout from '../../layout';
import { useDictionaries } from '../../hooks/useTraductor';
import './style.css';

function CellString({ value }) {
  return <div className="celValue">{value}</div>;
}

function CellStringUppercase({ value }) {
  return <div className="celValueUppercase">{value}</div>;
}

function CellDate({ value }) {
  return <div className="celValue">{format(new Date(value), 'dd.MM.yyyy')}</div>;
}

const interpretStatus = ({ startDate, stopDate, isEnabled, _id }) => {
  // isEnabled = determines that the contract was activated by the operator
  // isWithin =  determines whether the contract is active on a time basis, i.e. currently usable in a parking lot
  // isExpired = detects if the contract has expired, i.e. it has been used and is now over.

  const isWithin = isWithinInterval(new Date(), {
    start: new Date(startDate),
    end: new Date(stopDate),
  });

  const statusSelector = [
    {
      state: 'contracts-page-table-label-isEnabled-status-inactive',
      condition: !isEnabled,
      color: 'red',
    },
    {
      state: 'contracts-page-table-label-isEnabled-status-active',
      condition: !!isEnabled,
      color: 'black',
    },
    {
      state: 'contracts-page-table-label-isEnabled-status-expired',
      condition: isAfter(new Date(), new Date(stopDate)),
      color: 'red',
    },
  ];

  return statusSelector.filter(({ condition }) => condition).pop();
};

function CellState({ value, data }) {
  const text = useDictionaries();

  const { _id, startDate, stopDate, isEnabled } = data;

  const { state = 'Error', color } = interpretStatus({
    startDate,
    stopDate,
    isEnabled,
    _id,
  });

  return (
    <div className="celValue" style={{ color }}>
      {text(state)}
    </div>
  );
}

function CellIcon({ value, data }) {
  const navigate = useNavigate();

  const { _id } = data;

  return (
    <div
      className="iconContainer"
      role="button"
      onKeyPress={() => {}}
      tabIndex="0"
      onClick={() => navigate(`/contracts/${_id}/`)}
    >
      <div className="celValueCliclable">
        <svg
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          clipRule="evenodd"
        >
          <path
            fill="#ababab"
            d="M22 24h-20v-24h14l6 6v18zm-7-23h-12v22h18v-16h-6v-6zm3 15v1h-12v-1h12zm0-3v1h-12v-1h12zm0-3v1h-12v-1h12zm-2-4h4.586l-4.586-4.586v4.586z"
          />
        </svg>
      </div>
    </div>
  );
}

export default function Contracts() {
  const navigate = useNavigate();
  const text = useDictionaries();

  return (
    <Layout>
      <div className="root">
        <div className="backButtonContainer">
          <div
            className="backButton"
            role="button"
            onKeyPress={() => {}}
            tabIndex="0"
            onClick={() => navigate('/motorist')}
          >
            <h5 style={{}}>Dashboard ←</h5>
          </div>
        </div>
        <div className="titleMenu">{text('dashboard-home-tree-contracts-title')}</div>
        <div className="pageContractContainer">
          <div className="menuContainerContract">{text('contracts-page-sect1-title')}</div>
          <div style={{ width: '20px' }} />
          <div className="tableContainer">
            <GqlPagination
              id="_id"
              gql={QUERY_CONTRACTS}
              variables={{ test: 'test' }}
              style={{ width: 'calc(100% - 20px)' }}
            >
              <Field
                name="parking._id"
                decorator={(value) => text(`parking-${value}-name`)}
                label={text('contracts-page-table-label-parkingName')}
                component={CellStringUppercase}
                className="cell"
              />
              <Field
                name="ospContractId"
                label={text('contracts-page-table-label-id')}
                component={CellString}
                className="cell"
              />
              <Field
                name="category"
                label={text('contracts-page-table-label-category')}
                component={CellString}
                className="cell"
              />
              <Field
                name="startDate"
                label={text('contracts-page-table-label-startDate')}
                component={CellDate}
                className="cell"
              />
              <Field
                name="stopDate"
                label={text('contracts-page-table-label-stopDate')}
                component={CellDate}
                className="cell"
              />
              <Field
                name="isEnabled"
                label={text('contracts-page-table-label-isEnabled')}
                component={CellState}
                className="cell"
              />
              <Field
                name="createdAt"
                label={text('contracts-page-table-label-detail')}
                component={CellIcon}
                className="cell"
              />
            </GqlPagination>
          </div>
        </div>
      </div>
    </Layout>
  );
}
