import { gql } from '@apollo/client';

const SIGNING = gql`
  mutation login($username:String!, $password:String!){
    login(username: $username, password:$password){
      token
    }
  }
`;

export default SIGNING;
