import { gql } from '@apollo/client';

export const GET_SESSIONS = gql`
  query sessions($page: Int, $limit: Int, $order: String) {
    sessions(page: $page, limit: $limit, order: $order) {
      list {
        _id
        operatorId
        parkingId
        motoristId
        inconsistencyReasons
        isInconsistent
        createdAt
        updatedAt
        startDate
        endDate
        entryDate
        exitDate
        amount
        currency
        events {
          state
          technicalEventId
          credentials {
            type
            provider
            value
            description
          }
          accessPointId
          createdAt
        }
        lastAccessPointId
        state
        occupancyDuration
      }
      paging {
        limit
        count
        current
      }
    }
  }
`;

export const GET_SESSION = gql`
  query session($sessionId: String!) {
    session(sessionId: $sessionId) {
      _id
      operatorId
      parkingId
      motoristId
      productId
      contractId
      contract {
        _id
        startDate
        stopDate
      }
      product {
        _id
        name
        category
        credentialsTypes {
          provider
        }
        rules
        parameters
      }
      inconsistencyReasons
      isInconsistent
      createdAt
      updatedAt
      startDate
      endDate
      entryDate
      exitDate
      events {
        state
        technicalEventId
        credentials {
          type
          provider
          value
          description
        }
        accessPointId
        createdAt
      }
      lastAccessPointId
      state
      occupancyDuration
    }
  }
`;
