import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import FormEdit, { Field } from '../FormEdit';

export const GqlEdit = ({
  id,
  gql,
  data,
  buttonLabel = 'Update',
  onSuccess,
  // onBeforeSubmit,
  children,
  onlyChanged = false,
  submitButtonProps,
  formRef,
  ...props
}) => {
  const [loadingStep, setLoadingStep] = useState();

  const [update] = useMutation(gql, {
    update(cache) {
      cache.reset();
    },
    onCompleted(data) {
      setLoadingStep('success');
      setTimeout(() => {
        setLoadingStep();
        if (onSuccess) onSuccess(data);
      }, 1500);
    },
    onError(err) {
      console.log(err);
      setLoadingStep('error');
      setTimeout(() => setLoadingStep(), 2000);
    },
  });

  const handleSubmit = async (variables) => {
    setLoadingStep('loading');
    update({ variables });
  };

  return (
      <FormEdit
        id={id}
        data={data}
        onlyChanged={onlyChanged}
        buttonLabel={buttonLabel}
        step={loadingStep}
        onSubmit={handleSubmit}
        submitButtonProps={submitButtonProps}
        formRef={formRef}
        {...props}
      >
        {children}
      </FormEdit>
  );
};

export { Field };
