import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../layout';
import './style.css';
import tree from './tree';
import { useDictionaries } from '../../hooks/useTraductor';

export default function Home() {
  const text = useDictionaries();

  return (
    <Layout>
      <div className="root">
        <div className="rootBox">
          <h1 className="titlePage">{text('landing-page-sub-title')}</h1>
          <div className="containerBox">
            {tree.map(({ type, title, backgroundColor, options, link }) => (
              <div key={type} className="box">
                <div className="boxTitle">
                  <Link to={link} style={{ width: '100%', padding: '0px 10px 0px 0px' }}>
                    <div className="boxTitle" style={{ background: backgroundColor }}>
                      <h3 className="subTitleMenu">{text(title)}</h3>
                    </div>
                  </Link>
                </div>
                <div className="optionTitleBox">
                  {options.map(({ label }) => (
                    <div key={label} className="optionTitleContainer">
                      <p className="optionTitle">{text(label)}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}
