import React from 'react';
import { Button } from 'reactstrap';

export default function ButtonLoading({ loading, children, ...props }) {
  const icon = getIcon(loading);
  const color = getColor(loading);
  const isLoading = loading === 'loading';

  return (
    <Button type="submit" {...props} color={color} disabled={isLoading}>
      {icon ? <>{icon} </> : null}
      {children}
    </Button>
  );
}

function getIcon(step) {
  switch (step) {
    case 'loading':
      return <i className="pe-7s-refresh fa-spin" />;
    case 'success':
      return <i className="pe-7s-check" />;
    case 'error':
      return <i className="pe-7s-close-circle" />;
    default:
      return null;
  }
}

function getColor(step) {
  switch (step) {
    case 'loading':
      return 'info';
    case 'success':
      return 'success';
    case 'error':
      return 'danger';
    default:
      return 'primary';
  }
}
