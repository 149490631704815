import { Spinner, Table } from 'reactstrap';
import Field from './Field';
import React from 'react';

/**
 * Affiche une liste d'objets sour la forme d'un tableau
 * Field : chaque champs est décrit par un composant Field
 * qui peut utiliser un "decorator" (une simple fonction
 * recevant la valeur à afficher) ou un "component" (un
 * composant react qui reçoit toutes les données de l'objet)
 */
export default function List({
  id = false,
  data,
  children,
  loading = false,
  selectedLines = [],
  ...props
}) {
  return (
    <Table
      striped
      responsive
      hover
      size="sm"
      {...props}
      style={{ ...props?.style, background: '#f9f9f9' }}
    >
      <thead>
        <tr>
          {children.map((child) => (
            <th
              key={child.props.name}
              className={child.props.className}
              style={{
                fontFamily: 'Arial, Helvetica, sans-serif',
                textAlign: 'center',
                paddingTop: '1em',
              }}
            >
              <span className="firstUpper">{child.props.label}</span>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan={children.length} className="text-center">
              <Spinner type="grow" color="primary" />
            </td>
          </tr>
        ) : null}
        {!loading &&
          data.map((line, i) => (
            <React.Fragment key={i}>
              <tr
                key={`${i + 1}-row-${line}`}
                style={{
                  backgroundColor: selectedLines.includes(line[id]) ? '#30b1ff' : '',
                  fontFamily: 'Arial, Helvetica, sans-serif',
                  verticalAlign: 'middle',
                }}
              >
                {children.map((child, index) => {
                  return (
                    <child.type
                      key={index}
                      data={line}
                      value={
                        child.props.name.includes('.')
                          ? getFirstLevelValue(child.props.name, line)
                          : line[child.props.name]
                      }
                      {...child.props}
                    />
                  );
                })}
              </tr>
            </React.Fragment>
          ))}
      </tbody>
    </Table>
  );
}

// Récupère une valeur dans un sous-objet
function getFirstLevelValue(tag, line) {
  const levels = tag.split('.');
  line = line[levels[0]];
  return line ? line[levels[1]] : '-';
}

export { Field };
