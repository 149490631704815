import React, { Component } from 'react';
import { Field } from 'formik';
import { FormGroup, Label } from 'reactstrap';

class FormEditField extends Component {
  state = {
    dateFields: {},
    phone: '',
    company: undefined,
    store: undefined,
  };

  // -------------------------
  // Gère l'affichage des calendriers
  // -------------------------
  focusToogle(name, focused) {
    const data = this.state.dateFields;
    if (focused === data[name]) return;
    data[name] = focused;
    this.setState({ dateFields: data });
  }

  formatAddress(place) {
    const data = {
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
      address: place.formatted_address,
    };

    place.address_components.forEach((comp) => {
      const type = comp.types[0];
      data[type] = comp.long_name;
    });

    return data;
  }

  validatePhone(value) {
    let errorMessage;
    if (value !== '' && value !== '+' && !isValidPhoneNumber(value)) {
      errorMessage = 'Invalid phone number';
    }
    return errorMessage;
  }

  validateEmail(value) {
    let errorMessage;
    if (!value && this.props.isRequired) {
      errorMessage = 'This field is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      errorMessage = 'Invalid email address';
    }
    return errorMessage;
  }

  validateRequired(value) {
    let errorMessage;
    if (!value || value === '') {
      errorMessage = 'This field is required';
    }
    return errorMessage;
  }

  validateCompany(value) {
    let errorMessage;
    if (!this.state.company || this.state.company === '') {
      errorMessage = 'This field is required';
    }
    return errorMessage;
  }

  validateStore(value) {
    let errorMessage;
    if (!this.state.store || this.state.store === '') {
      errorMessage = 'This field is required';
    }
    return errorMessage;
  }

  formatComponent() {
    const { isRequired, labelClassName, ...otherProps } = this.props;

    switch (this.props.type) {
      case 'select':
        return (
          <>
            <Field
              component="select"
              name={this.props.name}
              validate={isRequired === true ? this.validateRequired : undefined}
              {...otherProps}
            >
              {this.props.children}
            </Field>
          </>
        );
      case 'textarea':
        return (
          <>
            <Field
              {...otherProps}
              component="textarea"
              name={this.props.name}
            />
          </>
        );
      case 'custom':
        return (
          <Field
            name={this.props.name}
            component={(props) => <this.props.custom {...otherProps} {...props} />}
          />
        );
      case 'email':
        return (
          <Field name={this.props.name} validate={this.validateEmail.bind(this)}>
            {({ field, form, ...props }) => (
              <input
                {...props}
                {...field}
                onFocus={() => form.setErrors({})}
              />
            )}
          </Field>
        );
      default:
        return (
          <Field
            name={this.props.name}
            validate={isRequired === true ? this.validateRequired : undefined}
          >
            {({ field, form, ...props }) => (
              <input
                type={this.props.type}
                {...props}
                {...otherProps}
                {...field}
                onFocus={() => form.setErrors({})}
              />
            )}
          </Field>
        );
    }
  }

  render() {
    const { type } = this.props;
    if (type === 'hidden') {
      return this.formatComponent();
    }
    return (
      <FormGroup style={{ padding: '0px 10px 0px 0px'}}>
        <Label className={this.props.labelClassName} >{`${this.props.label}`}</Label>
        <br />
        {this.formatComponent()}
        {this.props.error ? <small className="text-danger">{this.props.error}</small> : null}
      </FormGroup>
    );
  }
}

export default FormEditField;
