import { gql } from '@apollo/client';

export const GET_CONTRACT = gql`
  query contract($contractId: String!) {
    contract(contractId: $contractId) {
      _id
      ospContractId
      productId
      product {
        _id
        parkingId
        code
        name
        category
        startDate
        stopDate
        createdAt
      }
      motoristId
      parking {
        _id
        name
        code
        coordinates
      }
      parkingId
      startDate
      stopDate
      isEnabled
      createdAt
      category
      credentials {
        value
        type
        provider
        description
      }
    }
  }
`;

export const QUERY_CONTRACTS = gql`
  query contracts($page: Int, $limit: Int, $order: String) {
    contracts(page: $page, limit: $limit, order: $order) {
      list {
        _id
        ospContractId
        product {
          name
          code
        }
        parking {
          _id
          name
          code
          coordinates
        }
        startDate
        stopDate
        isEnabled
        createdAt
        category
      }
      paging {
        limit
        count
        current
      }
    }
  }
`;

export const UPDATE_CONTRACT = gql`
  mutation contract($contractId: String!, $plates: [PlateInput!]) {
    contract(contractId: $contractId, input: { plates: $plates }) {
      _id
      productId
      credentials {
        type
        value
        provider
        description
      }
      product {
        _id
        parkingId
        code
        name
        category
        startDate
        stopDate
        createdAt
      }
      motoristId
      parking {
        _id
        name
        code
        coordinates
      }
      parkingId
      startDate
      stopDate
      isEnabled
      createdAt
    }
  }
`;
