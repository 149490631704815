import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';
import { format } from 'date-fns';
import Layout from '../../layout';
import { GET_MOTORIST } from '../../GQL/motorist';
import { GET_SESSION } from '../../GQL/sessions';
import { useDictionaries } from '../../hooks/useTraductor';
import './style.css';
import { Duration } from '../../legacy_components/Duration';
import { useProvider } from '../../hooks/useProvider';

function Defile({ label, value, color }) {
  return (
    <div style={{ borderBottom: '1px solid #ababab20' }}>
      <div className="cardSession">
        <div className="cardSessionLabel">
          <p className="subLabelSession">{label}</p>
        </div>
        <div className="cardSessionValue">
          <p className="subLabelSession" style={{ color }}>
            {value}
          </p>
        </div>
      </div>
    </div>
  );
}

function ShortDefile({ label, value, color }) {
  return (
    <div style={{ borderBottom: '1px solid #ababab20' }}>
      <div className="shortCardSession">
        <div className="shortCardSessionLabel">
          <p className="subLabelSession">{label}</p>
        </div>
        <div className="cardSessionValue">
          <p className="subLabelSession" style={{ color }}>
            {value}
          </p>
        </div>
      </div>
      <div />
    </div>
  );
}

const useCredentials = (credentialValue) => {
  const [credential, setCredential] = useState({});
  const [getMotorist, { loading, error, data }] = useLazyQuery(GET_MOTORIST);

  useEffect(() => {
    if (credentialValue) {
      getMotorist();
    }
  }, [credentialValue]);

  useEffect(() => {
    if (data?.me?.credentials) {
      setCredential(data?.me?.credentials?.find(({ value }) => value === credentialValue));
    }
  }, [data]);

  return loading ? { type: '', value: '' } : credential;
};

const findLast = (array, filter) => {
  const result = array.filter(filter);
  return result[result.length - 1];
};

export default function Home() {
  const text = useDictionaries();
  const { id } = useParams();

  const { loading, data } = useQuery(GET_SESSION, {
    variables: { sessionId: id },
  });

  const navigate = useNavigate();

  const { parkingId, events = [], occupancyDuration, contract, amount, currency } = {
    ...data?.session,
  };

  // Handle dates and duration
  const ENTRY_AUTHORIZED = findLast(events, ({ state }) => state === 'ENTRY_AUTHORIZED');
  const ENTRY_DENIED = findLast(events, ({ state }) => state === 'ENTRY_DENIED');
  const ENTERED = findLast(events, ({ state }) => state === 'ENTERED');
  const EXITED = findLast(events, ({ state }) => state === 'EXITED');

  const entry =
    ENTERED?.createdAt ?? ENTRY_AUTHORIZED?.createdAt ?? ENTRY_DENIED?.createdAt ?? undefined;
  const exit = EXITED?.createdAt ?? undefined;

  const duration = parseInt(occupancyDuration);

  const feeWithCurrency =
    amount &&
    new Intl.NumberFormat('ch-CH', {
      style: 'currency',
      currency: currency,
      maximumSignificantDigits: 2,
    }).format(amount / 100);

  const entryAuthorizedCredential = ENTRY_AUTHORIZED?.credentials?.find(({ type }) =>
    ['PROVIDER_EXTERNAL_ID', 'PLATE'].includes(type)
  );
  const { value } = entryAuthorizedCredential ?? {};
  const { type, provider, description } = useCredentials(value);

  const credentialIsPlate = type === 'PLATE';
  const credentialIsCard = type === 'PROVIDER_EXTERNAL_ID';

  return (
    <Layout>
      <div className="root">
        <div className="backButtonContainer">
          <div
            className="backButton"
            role="button"
            onKeyPress={() => {}}
            tabIndex="0"
            onClick={() => navigate('/movements')}
          >
            <h5 style={{ textTransform: 'capitalize' }}>{`${text(
              'session-page-backButton-to-movement'
            )} ←`}</h5>
          </div>
        </div>
        <div className="titleMenu">{`${text('session-page-label-session')}`}</div>
        {loading ? (
          <div className="boxContainerMotorist">
            <div className="formMotoristContainer">
              <div className="sessionSubmitZoneLeft">loading...</div>
            </div>
          </div>
        ) : (
          <div className="boxContainerMotorist">
            <div className="formMotoristContainer">
              <div className="sessionSubmitZoneLeft">
                <p className="titleParkingName">{text(`parking-${parkingId}-name`)}</p>
                <div className="divider" style={{ width: 'calc(100% - 150px)' }} />
                <br />
                <div className="blowRowContainer">
                  <div className="blowRowItem">
                    <Defile
                      label={text('session-page-label-entryDate')}
                      value={entry ? format(new Date(entry), 'dd.MM.yyyy HH:mm:ss') : ''}
                    />
                    <Defile
                      label={text('session-page-label-stopDate')}
                      value={exit ? format(new Date(exit), 'dd.MM.yyyy HH:mm:ss') : ''}
                    />
                  </div>
                  <div className="blowRowItem">
                    <ShortDefile
                      label={text('session-page-label-duration')}
                      value={<Duration duration={duration} startDate={entry} endDate={exit} />}
                    />
                    {feeWithCurrency ? (
                      <ShortDefile label={text('session-page-label-fee')} value={feeWithCurrency} />
                    ) : (
                      <div style={{ height: '20px' }} />
                    )}
                  </div>
                </div>
                <br />
                <div className="divider" style={{ width: 'calc(100% - 150px)' }} />
                <br />
                {credentialIsPlate && (
                  <Defile label={text('session-page-label-licencePlate')} value={value} />
                )}
                {credentialIsCard && (
                  <Defile label={`${useProvider[provider.toUpperCase()]}`} value={value} />
                )}
                {description && (
                  <Defile label={text('session-page-label-description')} value={description} />
                )}
                <br />
                <div className="divider" style={{ width: 'calc(100% - 150px)' }} />
                <br />
                {contract ? (
                  <>
                    <Defile label={text('session-page-label-contractId')} value={contract?._id} />
                    <Defile
                      label={text('session-page-label-contractFrom')}
                      value={format(new Date(contract?.startDate), 'dd.MM.yyyy HH:mm:ss')}
                    />
                    <Defile
                      label={text('session-page-label-contractTo')}
                      value={format(new Date(contract?.stopDate), 'dd.MM.yyyy HH:mm:ss')}
                    />
                  </>
                ) : null}
                <br />
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}
