const countries = {
  FR: {
    en_EN: 'France',
    de_DE: 'Frankreich',
    fr_FR: 'France',
    it_IT: 'France',
  },
  DE: {
    en_EN: 'Germany',
    de_DE: 'Deutschland',
    fr_FR: 'Allemagne',
    it_IT: 'Germania',
  },
  IT: {
    en_EN: 'Italy',
    de_DE: 'Italien',
    fr_FR: 'Italie',
    it_IT: 'Italia',
  },
  CH: {
    en_EN: 'Switzerland',
    de_DE: 'Schweiz',
    fr_FR: 'Suisse',
    it_IT: 'Svizzera',
  },
};

export default countries;
