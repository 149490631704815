import React, { useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { PaginationTable, Field } from '../TablePagination/List';

export const GqlPagination = ({ id, gql, variables = {}, children, onPaging, onCompleted, ...props }) => {
  const [lines, setLines] = useState([]);
  const [currentPage, setPage] = useState(1);
  const [paging, setPaging] = useState({ count: 0, current: 1, limit: 10 });

  const definition = gql.definitions[0];
  const serviceName = definition['name'].value;

  const { data, loading } = useQuery(gql, {
    variables: { page: currentPage, limit: paging.limit, ...variables },
    onCompleted: (data) => (onCompleted ? onCompleted(data[serviceName].list) : null),
  });

  useEffect(() => {
    if (data) {
      setLines(data[serviceName].list);
      setPaging(data[serviceName].paging);
      if (onPaging) onPaging({ ...data[serviceName].paging });
      // Fix pagination issue when a research is done
      if (currentPage > data[serviceName].paging.count) setPage(1);
    }
  }, [gql, data, onPaging, currentPage, serviceName]);

  const changePage = (nextPage) => () => {
    setPage(nextPage);
  };

  return (
    <>
      <PaginationTable
        id={id}
        data={lines}
        paging={paging}
        loading={loading}
        onPageChange={changePage}
        {...props}
      >
        {children}
      </PaginationTable>
    </>
  );
};

export { Field };
