import { Form, Formik } from 'formik';
import React, { Component } from 'react';

import FormEditField from './Field';
import LoadingButton from '../Buttons/Loading';

class FormEdit extends Component {
  formatChildren(errors = {}) {
    return this.getFormattedFields(this.props.children, errors);
  }

  getFormattedFields(children, errors) {
    return React.Children.map(children, (child) => {
      if (!child || typeof child !== 'object') return child;

      const fieldName = child.props.name;

      if (child.type && child.type === FormEditField && fieldName && errors[fieldName]) {
        return React.cloneElement(child, { error: errors[fieldName] });
      }

      if (child.props && child.props.children) {
        const formattedChildren = this.getFormattedFields(child.props.children, errors);
        return React.cloneElement(child, { children: [...formattedChildren] });
      }

      return child;
    });
  }

  formatData(data) {
    const formattedData = { ...data };
    Object.keys(formattedData).forEach((key) => {
      const value = formattedData[key];
      if (value === null) formattedData[key] = '';
    });
    return formattedData;
  }

  render() {
    const {
      id,
      data,
      onSubmit,
      onBeforeSubmit,
      step = false,
      onlyChanged = false,
      buttonLabel,
      validate,
      submitButtonProps,
      formRef,
    } = this.props;

    const formattedData = this.formatData(data);

    if (step){
      return(
        <div style={{width: '600px', minHeight: '693px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <div className="loader" />        
        </div>
      )
    }

    return (
      <Formik
        initialValues={formattedData}
        validate={validate}
        validateOnBlur={false}
        validateOnChange={false}
        innerRef={formRef}
        onSubmit={async (values) => {
          // L'objet de résultat final
          const editedData = {};

          // On parcours chaque valeurs du formulaire pour
          // ne récupérer que celles qui ont été remplies
          Object.keys(values).forEach((key) => {
            if (values[key] === null) return;
            // On regarde si on ne doit retourner
            // que les champs modifiés (excepté l'ID)
            if (key !== id && onlyChanged && values[key] === data[key]) return;
            if (['false', 'true'].includes(values[key])) values[key] = values[key] === 'true';
            editedData[key] = values[key];
          });

          const modifiedData = onBeforeSubmit ? await onBeforeSubmit(editedData) : editedData;
          return onSubmit(modifiedData);
        }}
      >
        {({ handleSubmit, errors }) => {
          return (
            <Form onSubmit={handleSubmit}>
              {this.formatChildren(errors)}
              {buttonLabel && (
                <LoadingButton size="sm" loading={step} color="primary" {...submitButtonProps}>
                  {buttonLabel}
                </LoadingButton>
              )}
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default FormEdit;
export { FormEditField as Field };
