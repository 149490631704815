const tree = [
  {
    type: 'account',
    title: 'dashboard-home-tree-account-title',
    backgroundColor: '#BECCBF',
    link: '/account',
    options: [
      {
        label: 'dashboard-home-tree-account-set-and-actualize',
      },
      {
        label: 'dashboard-home-tree-account-personal-informations',
      },
      {
        label: 'dashboard-home-tree-account-password',
      },
      {
        label: 'dashboard-home-tree-account-phone-numbers',
      },
    ],
  },
  {
    type: 'contracts',
    title: 'dashboard-home-tree-contracts-title',
    backgroundColor: '#CDC0A7',
    link: '/contracts',
    options: [
      {
        label: 'dashboard-home-tree-contracts-see-contracts',
      },
    ],
  },
  {
    type: 'Movements',
    title: 'dashboard-home-tree-movements-title',
    backgroundColor: '#C6B079',
    link: '/movements',
    options: [
      {
        label: 'dashboard-home-tree-movements-see-history',
      },
      {
        label: 'dashboard-home-tree-movements-search',
      },
    ],
  },
];

export default tree;
