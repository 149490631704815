import { makeVar } from '@apollo/client';

export const updateMotorist = makeVar({
  logued: false,
  username: '',
  firstName: '',
  lastName: '',
  credentials: [],
  address1: '',
  address2: '',
  zipcode: '',
  city: '',
  country: '',
  phone: '',
  lang: undefined,
});

export const updateDictionary = makeVar({ en_EN: {} });

export const selectedLang = makeVar();
