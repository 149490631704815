import React from 'react';
import { useNavigate } from 'react-router-dom';
import { isEqual, format } from 'date-fns';

import { GqlPagination, Field } from '../../legacy_components/GqlPagination';
import { GET_SESSIONS } from '../../GQL/sessions';

import Layout from '../../layout';
import { useDictionaries } from '../../hooks/useTraductor';
import './style.css';
import { Duration } from '../../legacy_components/Duration';

const errorStates = ['EXIT_DENIED', 'ENTRY_DENIED'];

function CellString({ value, data }) {
  return (
    <div
      className="celValue"
      style={{ color: errorStates.includes(data?.state) ? 'red' : 'black' }}
    >
      {value}
    </div>
  );
}

const findLast = (array, filter) => {
  const result = array.filter(filter);
  return result[result.length - 1];
};

function renderDuration({ data }) {
  const ENTRY_AUTHORIZED = findLast(data.events, ({ state }) => state === 'ENTRY_AUTHORIZED');
  const ENTRY_DENIED = findLast(data.events, ({ state }) => state === 'ENTRY_DENIED');
  const ENTERED = findLast(data.events, ({ state }) => state === 'ENTERED');
  const EXITED = findLast(data.events, ({ state }) => state === 'EXITED');

  const entry =
    ENTERED?.createdAt ?? ENTRY_AUTHORIZED?.createdAt ?? ENTRY_DENIED?.createdAt ?? undefined;
  const exit = EXITED?.createdAt ?? undefined;

  const duration = parseInt(data.occupancyDuration);

  return (
    duration && entry && exit && <div
      className="celValue"
      style={{ color: errorStates.includes(data?.state) ? 'red' : 'black' }}
    >
      <Duration duration={duration} startDate={entry} endDate={exit} />
    </div>
  );
}

function renderFees({ value, data }) {
  return (
    <div
      className="celValueUppercase"
      style={{ color: errorStates.includes(data?.state) ? 'red' : 'black' }}
    >
      {value}
    </div>
  );
}

function renderExitDate({ data }) {
  const EXITED = findLast(data.events, ({ state }) => state === 'EXITED');
  const exit = EXITED?.createdAt ?? undefined;
  if (!exit) return <></>;

  const date = new Date(exit);
  const before1970 = isEqual(new Date('1970-01-01T00:00:00.000Z'), date);

  if (before1970) {
    return <div className="celValue" />;
  }

  return (
    <div
      className="celValue"
      style={{ color: errorStates.includes(data?.state) ? 'red' : 'black' }}
    >
      {format(new Date(exit), 'dd.MM.yyyy HH:mm:ss')}
    </div>
  );
}

function renderEntryDate({ data }) {
  const ENTRY_AUTHORIZED = findLast(data.events, ({ state }) => state === 'ENTRY_AUTHORIZED');
  const ENTRY_DENIED = findLast(data.events, ({ state }) => state === 'ENTRY_DENIED');
  const ENTERED = findLast(data.events, ({ state }) => state === 'ENTERED');

  const entry =
    ENTERED?.createdAt ?? ENTRY_AUTHORIZED?.createdAt ?? ENTRY_DENIED?.createdAt ?? undefined;

  if (!entry) return <></>;

  const date = new Date(entry);
  const before1970 = isEqual(new Date('1970-01-01T00:00:00.000Z'), date);

  if (errorStates.includes(data?.state)) {
    return (
      <div
        className="celValue"
        style={{ color: errorStates.includes(data?.state) ? 'red' : 'black' }}
      >
        {format(date, 'dd.MM.yyyy HH:mm:ss')}
      </div>
    );
  }

  if (before1970) {
    return <div className="celValue" />;
  }

  return <div className="celValue">{format(date, 'dd.MM.yyyy HH:mm:ss')}</div>;
}

function CellIcon({ value, data }) {
  const navigate = useNavigate();

  const { _id } = data;

  if (errorStates.includes(data?.state)) {
    return <div className="iconContainer" style={{ height: '30px' }} />;
  }

  return (
    <div
      className="iconContainer"
      role="button"
      onKeyPress={() => {}}
      tabIndex="0"
      onClick={() => navigate(`/session/${_id}/`)}
    >
      <div className="celValueCliclable">
        <svg
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          clipRule="evenodd"
        >
          <path
            fill="#ababab"
            d="M22 24h-20v-24h14l6 6v18zm-7-23h-12v22h18v-16h-6v-6zm3 15v1h-12v-1h12zm0-3v1h-12v-1h12zm0-3v1h-12v-1h12zm-2-4h4.586l-4.586-4.586v4.586z"
          />
        </svg>
      </div>
    </div>
  );
}

export default function Movements() {
  const navigate = useNavigate();
  const text = useDictionaries();

  return (
    <Layout>
      <div className="root">
        <div className="backButtonContainer">
          <div
            className="backButton"
            role="button"
            onKeyPress={() => {}}
            tabIndex="0"
            onClick={() => navigate('/motorist')}
          >
            <h5 style={{}}>Dashboard ←</h5>
          </div>
        </div>
        <div className="titleMenu">{text('dashboard-home-tree-movements-title')}</div>
        <div className="pageContractContainer">
          <div className="menuContainerSession">{text('movements-page-sect1-title')}</div>
          <div style={{ width: '20px' }} />
          <div className="tableContainerSession">
            <GqlPagination
              id="_id"
              gql={GET_SESSIONS}
              variables={{ test: 'test' }}
              style={{ width: 'calc(100% - 20px)' }}
            >
              <Field
                name="parkingId"
                component={CellString}
                decorator={(value) => text(`parking-${value}-name`)}
                className="cell"
                label={text('movement-page-table-label-localisation')}
              />
              <Field
                name="entryDate"
                component={renderEntryDate}
                className="cell"
                label={text('movement-page-table-label-entry')}
              />
              <Field
                name="exitDate"
                component={renderExitDate}
                className="cell"
                label={text('movement-page-table-label-exit')}
              />
              <Field
                name="occupancyDuration"
                component={renderDuration}
                className="cell"
                label={text('movement-page-table-label-duration')}
              />
              <Field
                name="amount"
                decorator={(value) =>
                  value
                    ? new Intl.NumberFormat('ch-CH', {
                        style: 'currency',
                        currency: 'ch-CH',
                        maximumSignificantDigits: 2,
                      }).format(value / 100)
                    : ''
                }
                label={text('movement-page-table-label-fee')}
                component={renderFees}
                className="cell"
              />
              <Field
                name="createdAt"
                label={text('movement-page-table-label-detail')}
                component={CellIcon}
                className="cell"
              />
            </GqlPagination>
          </div>
        </div>
      </div>
    </Layout>
  );
}
