import { gql } from '@apollo/client';

export const GET_MOTORIST = gql`
  query {
    me {
      _id
      gender
      username
      firstName
      lastName
      credentials {
        type
        value
        provider
        description
      }
      address1
      address2
      zipcode
      city
      country
      phone
      lang
      qrcode {
        createdAt
        token
      }
    }
  }
`;

export const UPDATE_MOTORIST = gql`
  mutation me(
    $username: String
    $firstName: String
    $plates: [PlateInput]
    $cards: [CardInput]
    $lastName: String
    $address1: String
    $address2: String
    $zipcode: String
    $city: String
    $country: String
    $phone: String
    $lang: String
    $gender: String
  ) {
    me(
      input: {
        username: $username
        firstName: $firstName
        lastName: $lastName
        plates: $plates
        cards: $cards
        address1: $address1
        address2: $address2
        zipcode: $zipcode
        city: $city
        country: $country
        phone: $phone
        lang: $lang
        gender: $gender
      }
    ) {
      _id
      credentials {
        type
        value
        provider
        description
      }
      gender
      username
      firstName
      lastName
      address1
      address2
      zipcode
      city
      country
      phone
      lang
    }
  }
`;
