import { gql } from '@apollo/client';

export const REGENERATE_QRCODE = gql`
  mutation regenerateQrcode($motoristId: String!, $contractId: String!) {
    regenerateQrcode(motoristId: $motoristId, contractId: $contractId) {
      motorist {
        credentials {
          type
          value
        }
      }
    }
  }
`;
