import React, { useEffect, useState, useMemo } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useSessionStorageString } from 'react-use-window-sessionstorage';

import { useReactiveVar, useLazyQuery } from '@apollo/client';
import Account from './pages/account';
import Movements from './pages/movements';
import Session from './pages/session';
import Contracts from './pages/contracts';
import Contract from './pages/contract';
import Motorist from './pages/motorist';
import Home from './pages/home';
import Confirmation from './pages/confirmation';
import LosePage404 from './pages/404';

import { useTraductor } from './hooks/useTraductor';

import { updateMotorist, selectedLang } from './reactiveVariables';
import { GET_MOTORIST } from './GQL/motorist';

import './global.css';

const useAuth = () => {
  const [getMotorist, { loading, error, data }] = useLazyQuery(GET_MOTORIST);
  const defaultToken = sessionStorage.getItem('session-token');
  const defaultIsLogin = defaultToken !== '';

  const [isLogin, setIsLogin] = useState(defaultIsLogin);
  const [token, setToken] = useSessionStorageString('session-token', defaultToken);

  useEffect(() => {
    const tokenExits = token !== '';
    if (tokenExits) {
      getMotorist();
    }
    setIsLogin(tokenExits);
  }, [token]);

  useEffect(() => {
    if (data?.me) {
      updateMotorist(data?.me);
      selectedLang(data?.me?.lang ?? 'en_EN');
      // console.log('main auth user', data?.me);
    }
  }, [data]);

  useEffect(() => {
    const msg = error?.message ?? '';
    switch (msg) {
      case '':
        break;
      case 'Unauthorized':
        setToken('');
        break;
      default:
        console.error('GQL ERROR', msg);
    }
  }, [error]);

  return { isLogin, token };
};

function Redirect({ cond = false, children, to = '/' }) {
  if (cond) return <Navigate to={to} replace />;
  return children;
}

export default function Main() {
  const { isLogin } = useAuth();
  const { isCharged } = useTraductor();

  if (!isCharged) {
    return <div>Loading traductions...</div>;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Redirect cond={isLogin} to="/motorist">
              <Home />
            </Redirect>
          }
        />
        <Route exact path="/confirmation" element={<Confirmation />} />
        <Route
          path="/motorist"
          element={
            <Redirect cond={!isLogin} to="/">
              <Motorist />
            </Redirect>
          }
        />
        <Route
          path="/account"
          element={
            <Redirect cond={!isLogin} to="/">
              <Account />
            </Redirect>
          }
        />
        <Route
          path="/contracts"
          element={
            <Redirect cond={!isLogin} to="/">
              <Contracts />
            </Redirect>
          }
        />
        <Route
          path="/movements"
          element={
            <Redirect cond={!isLogin} to="/">
              <Movements />
            </Redirect>
          }
        />
        <Route
          path="/contracts/:id"
          element={
            <Redirect cond={!isLogin} to="/">
              <Contract />
            </Redirect>
          }
        />
        <Route
          path="/session/:id"
          element={
            <Redirect cond={!isLogin} to="/">
              <Session />
            </Redirect>
          }
        />
        <Route path="*" element={<LosePage404 />} />
      </Routes>
    </BrowserRouter>
  );
}
