import React from 'react';

import TableList, { Field } from '../TableList/List';
import Pagination from '../Pagination/Pagination';

export const PaginationTable = ({
  id,
  data,
  paging,
  onPageChange,
  children,
  loading,
  ...props
}) => {
  return (
    <>
      <TableList id={id} data={data} loading={loading} {...props}>
        {children.filter((c) => !!c)}
      </TableList>
      <Pagination paging={paging} onPageChange={onPageChange} />
    </>
  );
};

export { Field };
