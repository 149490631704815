import { gql } from '@apollo/client';

export const CHECK_PLATE = gql`
  mutation checkPlates($input: CheckPlatesInput!) {
    checkPlates(input: $input) {
      acceptableContract {
        _id
      }
      rejectableContract {
        _id
        ospContractId
      }
    }
  }
`;

export const CHECK_CARDS = gql`
  mutation checkCards($input: CheckCardInput!) {
    checkCards(input: $input) {
      acceptableCards {
        value
        provider
      }
      rejectableCards {
        value
        provider
      }
    }
  }
`;
