import { gql } from '@apollo/client';

const GET_DICTIONARY = gql`
query i18n{
  i18n {
   _id
   key
   languages
   documents {
     _id
     translations
     lang
     createdAt
   }
   createdAt
 }
}
`;

export default GET_DICTIONARY;
