/* eslint-disable react/jsx-props-no-spreading */
import { useMutation, useReactiveVar } from '@apollo/client';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useNavigate } from 'react-router-dom';
import { CHECK_CARDS, CHECK_PLATE } from '../../GQL/checkCards';
import { UPDATE_MOTORIST } from '../../GQL/motorist';
import { useProvider } from '../../hooks/useProvider';
import { useDictionaries, useLangSelector } from '../../hooks/useTraductor';
import Layout from '../../layout';
import { Field, GqlEdit } from '../../legacy_components/GqlEdit';
import { updateMotorist } from '../../reactiveVariables';
import countries from './countries';
import './style.css';

const filterPlates = (credentials) =>
  credentials
    .filter(({ type }) => type === 'PLATE')
    .map(({ type, value, description }) => ({
      type,
      value,
      description,
    }));

const filterCards = (credentials) =>
  credentials
    .filter(({ type }) => type === 'PROVIDER_EXTERNAL_ID')
    .map(({ type, value, provider }) => ({
      type,
      value,
      provider,
    }));

function verify(value) {
  return value === '';
}

function Plate(props) {
  const { form, field, readOnly, setSubmitDisable, ...rest } = props;

  const text = useDictionaries();

  const { setFieldValue } = form;

  const plates = useMemo(() => filterPlates(field.value), [field.value]);
  const [editedPlates, setEditedPlate] = useState(plates);

  const handlePlateChange = (evt, index, type, description) => {
    setEditedPlate({
      ...editedPlates,
      [index]: {
        type,
        value: evt.target.value.replace(/[^a-zA-Z0-9ÖÜÄËÏöüäëï]{1,12}/g, '').toUpperCase(),
        description,
      },
    });
  };

  const onBlurChange = () => {
    setFieldValue('plates', Object.values(editedPlates), true);
  };

  const addCredential = () => {
    const newPlate = { type: 'PLATE', value: '', description: '' };
    setEditedPlate({
      ...editedPlates,
      [Object.keys(editedPlates).length]: newPlate,
    });
    setFieldValue(
      'plates',
      Object.values({ ...editedPlates, [Object.keys(editedPlates).length]: newPlate }),
      true
    );
  };

  const supCredential = (index) => {
    const newEditedPlates = [...Object.values(editedPlates)].filter(
      (values, currentIndex) => currentIndex !== index
    );

    setEditedPlate({
      ...newEditedPlates,
    });
    setFieldValue('plates', newEditedPlates, true);
  };

  if (!editedPlates) {
    return 'loading...';
  }

  return (
    <div>
      <div style={{ marginTop: '5px' }}>
        <p className="formSectionTitle2">{text('account-page-form-label-vehicles')}</p>
      </div>
      <div style={{ width: '450px' }} />
      <div className="containerCredentialForm">
        <div className="accountInput2" style={{ width: 'calc(100% - 50px)' }}>
          <p className="formSectionTitle3">{text('account-page-form-label-vehicles-plate')}</p>
        </div>
        <div className="accountInput2">
          <p className="formSectionTitle3">
            {text('account-page-form-label-vehicles-description')}
          </p>
        </div>
      </div>
      {plates.map(({ type, value, description }, index) =>
        type === 'PLATE' ? (
          <div key={`${index + value}`} className="containerCredentialForm">
            <input
              className={
                verify(editedPlates[index]?.value)
                  ? 'accountInput form-control form-control-sm is-invalid'
                  : 'accountInput form-control form-control-sm'
              }
              maxLength="12"
              minLength="1"
              readOnly={readOnly}
              disabled={readOnly}
              onBlur={onBlurChange}
              onChange={(evt) => handlePlateChange(evt, index, type, description)}
              value={editedPlates[index]?.value}
            />
            <div style={{ width: '25px', marginTop: '45px' }} />
            <input
              className="accountInput form-control form-control-sm"
              readOnly={readOnly}
              disabled={readOnly}
              onBlur={onBlurChange}
              onChange={(evt) =>
                setEditedPlate({
                  ...editedPlates,
                  [index]: { type, value, description: evt.target.value },
                })
              }
              value={editedPlates[index].description ?? ''}
            />
            <div style={{ width: '25px', marginTop: '45px' }} />
            {!readOnly ? (
              <div
                onClick={() => supCredential(index)}
                aria-hidden="true"
                style={{ width: '30px', cursor: 'pointer' }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                  <path d="M0 0h24v24H0z" fill="none" />
                </svg>
              </div>
            ) : (
              <div aria-hidden="true" style={{ width: '30px', cursor: 'pointer' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                />
              </div>
            )}
          </div>
        ) : null
      )}
      {!readOnly ? (
        <div className="addButton" type="button" aria-hidden="true" onClick={addCredential}>
          <div className="accountInput2">
            <p className="formSectionTitle2">{`+ ${text('account-page-form-label-card-add')}`}</p>
          </div>
        </div>
      ) : null}
    </div>
  );
}

function Cards(props) {
  const { form, field, readOnly, ...rest } = props;

  const text = useDictionaries();

  const { setFieldValue } = form;

  const cards = useMemo(() => filterCards(field.value), [field.value]);
  const [editedCards, setEditedPlate] = useState(cards);

  const onBlurChange = () => {
    setFieldValue('cards', Object.values(editedCards), true);
  };

  const addCredential = () => {
    const newPlate = { type: 'PROVIDER_EXTERNAL_ID', value: '', provider: 'FAKE' };
    setEditedPlate({
      ...editedCards,
      [Object.keys(editedCards).length]: newPlate,
    });
    setFieldValue(
      'cards',
      Object.values({ ...editedCards, [Object.keys(editedCards).length]: newPlate }),
      true
    );
  };

  const supCredential = (index) => {
    const newEditedPlates = [...Object.values(editedCards)].filter(
      (values, currentIndex) => currentIndex !== index
    );

    setEditedPlate({
      ...newEditedPlates,
    });
    setFieldValue('cards', newEditedPlates, true);
  };

  if (!editedCards) {
    return 'loading...';
  }

  return (
    <div>
      <div>
        <p className="formSectionTitle2">{text('account-page-form-label-card')}</p>
      </div>
      <div style={{ width: '450px' }} />
      <div className="containerCredentialForm">
        <div className="accountInput2" style={{ width: 'calc(100% - 50px)' }}>
          <p className="formSectionTitle3">{text('account-page-form-label-card-type')}</p>
        </div>
        <div className="accountInput2">
          <p className="formSectionTitle3">{text('account-page-form-label-card-number')}</p>
        </div>
      </div>
      {cards.map(({ type, value, provider }, index) =>
        type === 'PROVIDER_EXTERNAL_ID' ? (
          <div key={`${index + value}`} className="containerCredentialForm">
            <div className="select-wrapper" id="label-for-1">
              <select
                className="accountInput form-control form-control-sm"
                id="1"
                value={editedCards[index].provider ?? ''}
                onBlur={onBlurChange}
                disabled={readOnly}
                onChange={(evt) =>
                  setEditedPlate({
                    ...editedCards,
                    [index]: { type, value, provider: evt.target.value },
                  })
                }
              >
                {['FAKE', 'TCS', 'SUISSE-PASS', 'SKIDATA', 'TCSOSP'].map((option) => (
                  <option key={option} value={option}>
                    {useProvider[option]}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ width: '25px', marginTop: '45px' }} />
            <input
              className="accountInput form-control form-control-sm"
              readOnly={readOnly}
              disabled={readOnly}
              onBlur={onBlurChange}
              onChange={(evt) =>
                setEditedPlate({
                  ...editedCards,
                  [index]: { type, value: evt.target.value, provider },
                })
              }
              value={editedCards[index].value}
            />
            <div style={{ width: '25px', marginTop: '45px' }} />
            {!readOnly ? (
              <div
                onClick={() => supCredential(index)}
                aria-hidden="true"
                style={{ width: '30px', cursor: 'pointer' }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                  <path d="M0 0h24v24H0z" fill="none" />
                </svg>
              </div>
            ) : (
              <div aria-hidden="true" style={{ width: '30px', cursor: 'pointer' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                />
              </div>
            )}
          </div>
        ) : null
      )}
      {/* {!readOnly ? (
        <div className="addButton" type="button" aria-hidden="true" onClick={addCredential}>
          <div className="accountInput2">
            <p className="formSectionTitle2">{`+x ${text(
              'account-page-form-label-vehicles-add'
            )}`}</p>
          </div>
        </div>
      ) : null} */}
    </div>
  );
}

export default function Home() {
  const [checkCards, { loading, error, data }] = useMutation(CHECK_CARDS);
  const [checkPlates, { loading: platesLoading, error: platesError, data: platesData }] =
    useMutation(CHECK_PLATE);
  const [active, setActive] = useState(true);
  const text = useDictionaries();
  const { lang } = useLangSelector();

  const test = Object.entries(countries).sort(compare);
  console.log('test', test);

  const navigate = useNavigate();
  const formRef = useRef();
  const motorist = useReactiveVar(updateMotorist);
  const [submitZoneState, setSubmitZoneState] = useState('idle');
  const [alertMessage, setAlert] = useState(null);
  const [submitDisable, setSubmitDisable] = useState(false);

  const motoristData = useMemo(
    () => ({
      ...motorist,
      address2: motorist?.address2 ?? '',
      plates: filterPlates(motorist?.credentials ?? []),
      cards: filterCards(motorist?.credentials ?? []),
    }),
    [motorist, submitZoneState]
  );

  const handleSubmit = () => {
    if (formRef.current) {
      const cards = formRef?.current?.values?.cards ?? [];
      const plates = formRef?.current?.values?.plates ?? [];

      const externalsIdsCards = cards
        .filter(({ type }) => type === 'PROVIDER_EXTERNAL_ID')
        .map(({ value, type, provider }) => ({ value, type, provider }));
      const platesList = plates
        .filter(({ type }) => type === 'PLATE')
        .map(({ value, type, provider }) => ({ value, type }));

      checkCards({ variables: { input: { cards: externalsIdsCards } } });
      checkPlates({ variables: { input: { plates: platesList } } });
      // formRef.current.handleSubmit();
      // setSubmitZoneState('idle');
    }
  };

  const handleCancel = () => {
    setActive(false);
    setSubmitZoneState('idle');
  };

  useEffect(() => {
    if (data?.checkCards) {
      const { rejectableCards, acceptableCards } = data.checkCards;

      //reduire le nombre de plaque visible, ajouter le provider
      const plates = formRef?.current?.values?.plates ?? [];
      const plateValue = plates?.map((item) => item.value);
      const cardsValue = acceptableCards.map((item) => item.value);
      let duplicatedCards = [];
      const foundDuplicate = acceptableCards.some((item, idx) => {
        if (cardsValue.indexOf(item.value) != idx) {
          duplicatedCards.push(item);
        }
        return cardsValue.indexOf(item.value) != idx;
      });
      let duplicatedPlates = [];
      const foundDuplicatePlates = plates.some((item, idx) => {
        if (plateValue.indexOf(item.value) != idx) {
          duplicatedPlates.push(item);
        }
        return plateValue.indexOf(item.value) != idx;
      });

      const platesErrors = plates
        .filter(({ type }) => type === 'PLATE')
        .filter(({ value }) => verify(value));

      if (
        rejectableCards.length > 0 ||
        platesErrors.length > 0 ||
        foundDuplicate ||
        foundDuplicatePlates
      ) {
        setAlert(
          <SweetAlert
            type="danger"
            title={text('account-alert-title-rejected-credentials')}
            onConfirm={() => setAlert(null)}
            confirmBtnBsStyle="danger"
          >
            <h6>{text('account-alert-message-rejected-credentials')}</h6>
            {foundDuplicatePlates ? (
              <div style={{ color: 'red', marginBottom: '0.5em' }}>
                {text('account-alert-message-duplicate-plates')}
              </div>
            ) : (
              <></>
            )}
            {foundDuplicate ? (
              <div style={{ color: 'red', marginBottom: '1em' }}>
                {text('account-alert-message-duplicate-cards')}
              </div>
            ) : (
              <></>
            )}
            <div style={{ textAlign: 'left', paddingLeft: '30%' }}>
              {duplicatedPlates.map((item, i) => (
                <div
                  key={`${i + 1}${item}${i + 1}`}
                  style={{ color: 'red' }}
                >{`✖ ${item.description}: ${item.value}`}</div>
              ))}
              {duplicatedCards.map((card, index) => (
                <div
                  key={`${card.value}-${index + 1}`}
                  style={{ color: 'red' }}
                >{`✖ ${card.provider}: ${card.value}`}</div>
              ))}
              {!duplicatedCards ? (
                acceptableCards.map(({ provider, value }, i) => (
                  <div key={`${i + 1}${provider}${value}`}>{`✅ ${provider}: ${value}`}</div>
                ))
              ) : (
                <></>
              )}
              {rejectableCards.map(({ provider, value }, i) => (
                <div key={`${i + 1}${provider}${value}`} style={{ color: 'red' }}>
                  {`✖ ${provider}: ${value}`}
                </div>
              ))}
              {platesErrors.map(({ description, value }, i) => (
                <div key={`${i + 1}${description}${value}`} style={{ color: 'red' }}>
                  {`✖ PLATE: "${value}"`}
                </div>
              ))}
            </div>
          </SweetAlert>
        );
      }
      if (platesData?.checkPlates) {
        const { rejectableContract = [] } = platesData?.checkPlates;
        if (rejectableContract.length > 0) {
          // setAlert(<SweetAlert type="error" title="Here's a message!" onConfirm={() => setAlert(null)} />);
          setAlert(
            <SweetAlert
              type="danger"
              title={text('account-alert-title-rejected-credentials-in-contract')}
              onConfirm={() => setAlert(null)}
              confirmBtnBsStyle="danger"
            >
              <h6>{text('account-alert-message-rejected-credentials-in-contract')}</h6>

              <div style={{ color: 'red', marginBottom: '0.5em', marginTop: '1em' }}>
                {text('account-alert-message-rejected-plates-in-contract')}
              </div>

              <div style={{ textAlign: 'left', paddingLeft: '40%', paddingTop: '1em' }}>
                {rejectableContract.map((item, i) => (
                  <div
                    key={`${i + 1}${item}${i + 1}`}
                    style={{ color: 'red' }}
                  >{`✖ ${item.ospContractId}`}</div>
                ))}
              </div>
            </SweetAlert>
          );
        }
      }
      if (
        rejectableCards.length === 0 &&
        platesErrors.length === 0 &&
        !foundDuplicate &&
        !foundDuplicatePlates &&
        formRef.current
      ) {
        formRef.current.handleSubmit();
        setSubmitZoneState('idle');
      }
    }
  }, [data, platesData]);

  useEffect(() => {
    if (active === false) {
      const timer = setTimeout(() => {
        console.log('This will run after 1 second!');
        setActive(true);
      }, 300);
      return () => clearTimeout(timer);
    }

    return () => true;
  }, [active]);

  function compare(a, b) {
    if (a[1][lang] < b[1][lang]) {
      return -1;
    }
    if (a[1][lang] > b[1][lang]) {
      return 1;
    }
    return 0;
  }

  if (!lang || !active) {
    return (
      <Layout>
        <div className="root">
          <div className="backButtonContainer">
            <div
              className="backButton"
              role="button"
              onKeyPress={() => {}}
              tabIndex="0"
              onClick={() => navigate('/motorist')}
            >
              <h5>Dashboard ←</h5>
            </div>
          </div>
          <div className="titleMenu">{text('dashboard-home-tree-account-title')}</div>
          <div
            className="boxContainerMotorist"
            style={{
              height: 'calc(100vh - 100px)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                width: '600px',
                minHeight: '693px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div className="loader" />
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="root">
        {alertMessage}
        <div className="backButtonContainer">
          <div
            className="backButton"
            role="button"
            onKeyPress={() => {}}
            tabIndex="0"
            onClick={() => navigate('/motorist')}
          >
            <h5>Dashboard ←</h5>
          </div>
        </div>
        <div className="titleMenu">{text('dashboard-home-tree-account-title')}</div>
        <div className="boxContainerMotorist">
          <div className="formMotoristContainer">
            {motorist && active ? (
              <GqlEdit
                id="_id"
                gql={UPDATE_MOTORIST}
                data={motoristData}
                buttonLabel="Create"
                onSuccess={(evt) => console.debug(evt)}
                style={{ with: '100%', background: 'red' }}
                formRef={formRef}
                submitButtonProps={{
                  style: { display: 'none' },
                }}
              >
                <div className="formMotorist">
                  <div style={{ width: '100%' }}>
                    <p className="formSectionTitle">{text('account-page-sub-title')}</p>
                  </div>
                  <Field
                    disabled
                    type="text"
                    name="username"
                    label={text('account-page-form-label-username')}
                    className="accountInput form-control form-control-sm"
                    labelClassName="labelForm"
                    style={{ width: '250px', textTransform: 'lowercase' }}
                  />
                  <div className="accountInput" style={{ width: '240px' }} />
                  <Field
                    disabled={submitZoneState !== 'editing'}
                    type="select"
                    name="lang"
                    label={text('account-page-form-label-lang')}
                    className="accountInput form-control form-control-sm"
                    labelClassName="labelForm"
                    style={{ width: '200px' }}
                  >
                    <option value="en_EN">EN</option>
                    <option value="fr_FR">FR</option>
                    <option value="it_IT">IT</option>
                    <option value="de_DE">DE</option>
                  </Field>
                  <div className="accountInput" style={{ width: '170px' }} />
                  <Field
                    disabled={submitZoneState !== 'editing'}
                    type="select"
                    name="gender"
                    label={text('account-page-form-label-gender')}
                    className="accountInput form-control form-control-sm"
                    labelClassName="labelForm"
                    style={{ width: '205px' }}
                  >
                    <option value="MALE">{text('account-page-form-option-male')}</option>
                    <option value="FEMALE">{text('account-page-form-option-female')}</option>
                    <option value="UNKNOWN">{text('account-page-form-option-unknown')}</option>
                  </Field>
                  <div className="accountInput" style={{ width: '600px' }} />
                  <Field
                    disabled={submitZoneState !== 'editing'}
                    type="text"
                    name="firstName"
                    label={text('account-page-form-label-firstName')}
                    className="accountInput form-control form-control-sm"
                    labelClassName="labelForm"
                    style={{ width: '205px', textTransform: 'capitalize' }}
                  />
                  <Field
                    disabled={submitZoneState !== 'editing'}
                    type="text"
                    name="lastName"
                    label={text('account-page-form-label-lastName')}
                    className="accountInput form-control form-control-sm"
                    labelClassName="labelForm"
                    style={{ width: '205px', textTransform: 'capitalize' }}
                  />
                  <Field
                    disabled={submitZoneState !== 'editing'}
                    type="text"
                    name="address1"
                    label={text('account-page-form-label-address')}
                    className="accountInput form-control form-control-sm"
                    labelClassName="labelForm"
                    style={{ width: '280px' }}
                  />
                  <Field
                    disabled={submitZoneState !== 'editing'}
                    type="text"
                    name="zipcode"
                    label={text('account-page-form-label-zipcode')}
                    className="accountInput form-control form-control-sm"
                    labelClassName="labelForm"
                    style={{ width: '127px' }}
                  />
                  <Field
                    disabled={submitZoneState !== 'editing'}
                    type="text"
                    name="address2"
                    label={text('account-page-form-label-address-complement')}
                    className="accountInput form-control form-control-sm"
                    labelClassName="labelForm"
                    style={{ width: '220px' }}
                  />
                  <Field
                    disabled={submitZoneState !== 'editing'}
                    type="text"
                    name="city"
                    label={text('account-page-form-label-city')}
                    className="accountInput form-control form-control-sm"
                    labelClassName="labelForm"
                    style={{ width: '187px', textTransform: 'capitalize' }}
                  />
                  <div className="accountInput" style={{ width: '40px' }} />
                  <Field
                    disabled={submitZoneState !== 'editing'}
                    type="select"
                    name="country"
                    label={text('account-page-form-label-country')}
                    className="accountInput form-control form-control-sm"
                    labelClassName="labelForm"
                    style={{ width: '215px', textTransform: 'capitalize' }}
                  >
                    <option value="none" key="none">
                      {' '}
                    </option>
                    {Object.entries(countries)
                      .sort(compare)
                      .map(([countryCode, traductions]) => (
                        <option value={countryCode} key={countryCode}>
                          {traductions[lang]}
                        </option>
                      ))}
                  </Field>
                  <div className="accountInput" style={{ width: '165px' }} />
                  <Field
                    disabled={submitZoneState !== 'editing'}
                    type="text"
                    name="phone"
                    label={text('account-page-form-label-phone')}
                    className="accountInput form-control form-control-sm"
                    labelClassName="labelForm"
                    style={{ width: '190px' }}
                  />
                  <Field
                    readOnly={submitZoneState !== 'editing'}
                    type="custom"
                    name="plates"
                    label=""
                    className="accountInput form-control form-control-sm"
                    style={{ width: '400px' }}
                    custom={({ ...rest }) => (
                      <Plate {...rest} setSubmitDisable={setSubmitDisable} />
                    )}
                  />
                </div>
                <Field
                  readOnly={true}
                  type="custom"
                  name="cards"
                  label=""
                  className="accountInput form-control form-control-sm"
                  style={{ width: '400px' }}
                  custom={Cards}
                />
              </GqlEdit>
            ) : null}

            {submitZoneState === 'editing' ? (
              <div className="submitZoneRight">
                <div className="submitZoneContainer">
                  <div
                    className="submitButtonAccountForm"
                    onClick={submitDisable ? () => true : handleSubmit}
                    type="button"
                    aria-hidden="true"
                    style={{ background: submitDisable ? 'grey' : '' }}
                  >
                    <span>{text('account-page-form-submitZone-confirm')}</span>
                  </div>
                  <div
                    className="submitButtonAccountForm"
                    onClick={handleCancel}
                    type="button"
                    aria-hidden="true"
                  >
                    {text('account-page-form-submitZone-cancel')}
                  </div>
                </div>
              </div>
            ) : null}

            {submitZoneState === 'idle' ? (
              <div className="submitZoneRight">
                <div className="submitZoneContainer">
                  <div
                    className="submitButtonAccountForm"
                    onClick={() => setSubmitZoneState('editing')}
                    type="button"
                    aria-hidden="true"
                  >
                    {text('account-page-form-submitZone-edit')}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Layout>
  );
}
