import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactiveVar, gql, useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { useFormikContext } from 'formik';
import { isWithinInterval, isAfter, format } from 'date-fns';
import { capitalize, Modal } from '@mui/material';
import Layout from '../../layout';
import { GqlEdit, Field } from '../../legacy_components/GqlEdit';
import { GET_CONTRACT, UPDATE_CONTRACT, QUERY_CONTRACTS } from '../../GQL/contract';

import { GET_MOTORIST } from '../../GQL/motorist';
import { useDictionaries } from '../../hooks/useTraductor';
import './style.css';
import { useProvider } from '../../hooks/useProvider';
import { REGENERATE_QRCODE } from '../../GQL/regenerate-qrcode.gql';
import axios from 'axios';
import { BFF_HOST, BFF_AUTH_TOKEN } from '../../common/config/config';
function ToggleSwitch({ isToggled, onChange = () => true }) {
  const onToggle = () => onChange(!isToggled);
  return (
    <label className="toggle-switch">
      {isToggled}
      <input type="checkbox" checked={isToggled} onChange={onToggle} />
      <span className="switch" />
    </label>
  );
}

const filterPlates = (credentials) =>
  credentials
    .filter(({ type }) => type === 'PLATE')
    .map(({ type, value, description }) => ({
      type,
      value,
      description,
    }));

const filterCards = (credentials) =>
  credentials
    .filter(({ type }) => type === 'PROVIDER_EXTERNAL_ID')
    .map(({ type, value, provider }) => ({
      type,
      value,
      provider,
    }));

function Plate(props) {
  const text = useDictionaries();
  const [getMotorist, { loading, error, data }] = useLazyQuery(GET_MOTORIST);

  const { form, field, readOnly, handleSubmit, ...rest } = props;

  const { setFieldValue } = form;
  const motoristUserCredentials = useMemo(() => data?.me?.credentials ?? [], [data]);

  const contractPlates = useMemo(() => filterPlates(field.value), [field.value]);
  const filteredContractPlates = contractPlates.filter(
    (plate) => !motoristUserCredentials.find((credential) => credential.value === plate.value)
  );

  const [editedPlates, setEditedPlate] = useState(filteredContractPlates);

  const addCredential = (newPlate) => {
    const tempPlates = Object.values(editedPlates).filter(
      (plate) =>
        motoristUserCredentials.filter((credential) => credential.value === plate.value).length > 0
    );
    const newEditedPlates = [...tempPlates, newPlate];

    setEditedPlate({
      ...newEditedPlates,
    });

    setFieldValue(
      'plates',
      Object.values({ ...editedPlates, [tempPlates.length]: newPlate }),
      true
    );

    handleSubmit();
  };

  const supCredential = ({ type, value, description }) => {
    const newEditedPlates = [...Object.values(editedPlates)].filter(
      (plate) => plate.value !== value
    );

    setEditedPlate({
      ...newEditedPlates,
    });

    setFieldValue('plates', newEditedPlates, true);

    handleSubmit();
  };

  const handleSwitch = ({ type, value, description, isToggled }) => {
    if (isToggled) {
      addCredential({ type, value, description });
    } else {
      supCredential({ type, value, description });
    }
  };

  if (!editedPlates) {
    return 'loading...';
  }

  useEffect(() => {
    getMotorist();
  }, []);

  if (loading || !data) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div style={{ marginTop: '5px' }}>
        <p className="formSectionTitle2">{text('contract-page-form-label-vehicles')}</p>
      </div>
      <div style={{ width: '450px' }} />
      <div className="containerCredentialForm">
        <div className="accountInputContract2" style={{ width: 'calc(100% - 50px)' }}>
          <p className="formSectionTitle3">{text('contract-page-form-label-vehicles-plate')}</p>
        </div>
        <div className="accountInputContract2">
          <p className="formSectionTitle3">
            {text('contract-page-form-label-vehicles-description')}
          </p>
        </div>
      </div>
      {motoristUserCredentials.map(({ type, value, description }, index) =>
        type === 'PLATE' ? (
          <div key={`${index + value}`} className="containerCredentialForm">
            <input
              className="accountInputContract form-control form-control-sm"
              readOnly
              disabled
              value={
                motoristUserCredentials.find(
                  (contractCredential) => value === contractCredential?.value
                )?.value
              }
            />
            <div style={{ width: '25px', marginTop: '45px' }} />
            <input
              className="accountInputContract form-control form-control-sm"
              readOnly
              disabled
              value={
                motoristUserCredentials.find(
                  (contractCredential) => value === contractCredential?.value
                )?.description
              }
            />
            <div style={{ width: '25px', marginTop: '45px' }} />
            <div aria-hidden="true" style={{ width: '30px', cursor: 'pointer' }}>
              <ToggleSwitch
                isToggled={
                  !!contractPlates.find((contractCredential) => value === contractCredential?.value)
                    ?.value
                }
                onChange={(e) =>
                  handleSwitch({
                    type,
                    value,
                    description,
                    isToggled: e,
                  })
                }
              />
            </div>
          </div>
        ) : null
      )}
      {filteredContractPlates.map(({ type, value, description }, index) =>
        type === 'PLATE' ? (
          <div key={`${index + value}`} className="containerCredentialForm">
            <input
              className="accountInputContract form-control form-control-sm"
              readOnly
              disabled
              value={
                filteredContractPlates.find(
                  (contractCredential) => value === contractCredential?.value
                )?.value
              }
            />
            <div style={{ width: '25px', marginTop: '45px' }} />
            <input
              className="accountInputContract form-control form-control-sm"
              readOnly
              disabled
              value={
                filteredContractPlates.find(
                  (contractCredential) => value === contractCredential?.value
                )?.description
              }
            />
            <div style={{ width: '25px', marginTop: '45px' }} />
            <div aria-hidden="true" style={{ width: '30px', cursor: 'pointer' }}>
              <ToggleSwitch
                isToggled={
                  !!filteredContractPlates.find(
                    (contractCredential) => value === contractCredential?.value
                  )?.value
                }
                onChange={(e) =>
                  handleSwitch({
                    type,
                    value,
                    description,
                    isToggled: e,
                  })
                }
              />
            </div>
          </div>
        ) : null
      )}
    </div>
  );
}
function Qrcode(props) {
  const text = useDictionaries();
  const [getMotorist, { loading, error, data }] = useLazyQuery(GET_MOTORIST);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [update] = useMutation(REGENERATE_QRCODE, {
    update(cache) {
      cache.reset();
    },
    onCompleted(data) {
      console.log('🚀 ~ file: index.jsx:186 ~ onCompleted ~ data:', data);
    },
    onError(err) {
      console.log(err);
    },
  });
  const [
    getContracts,
    { loading: contractLoading, error: contractError, data: dataContract },
  ] = useLazyQuery(QUERY_CONTRACTS);
  useEffect(() => {
    getContracts();
  }, []);
  console.log('🚀 ~ file: index.jsx:54 ~ Plate ~ dataContract:', dataContract);

  const { form, field, readOnly, handleSubmit, ...rest } = props;
  const onClickDownload = async () => {
    console.log(data.me);
    await axios({
      url: `${BFF_HOST}/qrcode/downloadPdf?motoristId=${data.me._id}&contractId=${form.values.contractId}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${BFF_AUTH_TOKEN}`,
      },
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.pdf');
      document.body.appendChild(link);
      link.click();
    });
  };

  const { setFieldValue } = form;

  const contractPlates = useMemo(() => filterPlates(field.value), [field.value]);

  const motoristUserCredentials = useMemo(() => data?.me?.credentials ?? [], [data]);

  const [editedPlates, setEditedPlate] = useState(contractPlates);

  const addCredential = (newPlate) => {
    const tempPlates = Object.values(editedPlates).filter(
      (plate) =>
        motoristUserCredentials.filter((credential) => credential.value === plate.value).length > 0
    );
    const newEditedPlates = [...tempPlates, newPlate];

    setEditedPlate({
      ...newEditedPlates,
    });

    setFieldValue(
      'plates',
      Object.values({ ...editedPlates, [tempPlates.length]: newPlate }),
      true
    );

    handleSubmit();
  };

  const handleRegenerate = () => {
    console.log(data.me);
    update({ variables: { motoristId: data.me._id, contractId: form.values.contractId } });
    window.location.reload();
  };
  useEffect(() => {
    getMotorist();
  }, []);

  if (loading || !data) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div style={{ marginTop: '5px' }}>
        <p className="formSectionTitle2">{text('contract-page-form-label-qrcode')}</p>
      </div>
      <div style={{ width: '450px' }} />
      <div className="containerCredentialForm">
        <div className="accountInputContract2">
          <p className="formSectionTitle3">{text('contract-page-form-label-qrcode-generated')}</p>
        </div>
      </div>
      {motoristUserCredentials.map(({ type, value, description }, index) =>
        type === 'QRCODE' ? (
          <div key={`${index + value}`} className="containerQrForm">
            <input
              className="accountInputContract form-control form-control-sm"
              readOnly
              disabled
              value={
                data.me.qrcode.createdAt
                  ? format(new Date(data.me.qrcode.createdAt), 'dd.MM.yyyy HH:mm:ss')
                  : ''
              }
            />
            <div style={{ marginTop: '45px' }} />
            <button
              type="button"
              className="accountInputContract form-control form-control-sm"
              style={{ backgroundColor: '#C81400', marginLeft: '2em', color: 'white' }}
              onClick={() => onClickDownload()}
            >
              {text('contract-page-form-label-qrcode-download')}
            </button>
            <div style={{ marginTop: '45px' }} />
            <button
              type="button"
              className="accountInputContract form-control form-control-sm"
              style={{ backgroundColor: '#C81400', color: 'white', marginLeft: '2em' }}
              onClick={() => handleOpen()}
            >
              {text('contract-page-form-label-qrcode-regenerate')}
            </button>
          </div>
        ) : null
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '1em',
            backgroundColor: 'white',
            padding: '3em',
          }}
        >
          <div
            id="modal-modal-title"
            style={{
              padding: '1em',
              textAlign: 'center',
            }}
          >
            <h2>{text('contract-page-regenerate-modal-first-p')}</h2>
          </div>
          <div
            id="modal-modal-description"
            style={{
              textAlign: 'center',
              paddingBottom: '3em',
            }}
          >
            {text('contract-page-regenerate-modal-second-p')}
          </div>
          <div
            id="modal-modal-description"
            style={{
              textAlign: 'center',
              paddingBottom: '3em',
            }}
          >
            {[...new Set(dataContract?.contracts?.list.map((element) => element.parking._id))].map(
              (element) => (
                <span key={element}>{capitalize(text(`parking-${element}-name`))}</span>
              )
            )}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <button
              style={{
                backgroundColor: '#FB8C00',
                borderColor: '#FB8C00',
                padding: '0.5em 2em',
                borderRadius: '10px',
                color: 'white',
              }}
              onClick={() => handleRegenerate()}
            >
              {text('contract-page-regenerate-modal-button-proceed')}
            </button>
            <button
              style={{
                backgroundColor: '#C81400',
                borderColor: '#C81400',
                padding: '0.5em 2em',
                borderRadius: '10px',
                color: 'white',
              }}
              onClick={handleClose}
            >
              {text('contract-page-regenerate-modal-button-cancel')}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
function Cards(props) {
  const text = useDictionaries();

  const { form, field, readOnly, ...rest } = props;

  const { setFieldValue } = form;

  const cards = useMemo(() => filterCards(field.value), [field.value]);
  const [editedCards, setEditedPlate] = useState(cards);

  const onBlurChange = () => {
    setFieldValue('cards', Object.values(editedCards), true);
  };

  const addCredential = () => {
    const newPlate = { type: 'PROVIDER_EXTERNAL_ID', value: '', provider: '' };
    setEditedPlate({
      ...editedCards,
      [Object.keys(editedCards).length]: newPlate,
    });
    setFieldValue(
      'cards',
      Object.values({ ...editedCards, [Object.keys(editedCards).length]: newPlate }),
      true
    );
  };

  const supCredential = (index) => {
    const newEditedPlates = [...Object.values(editedCards)].filter(
      (values, currentIndex) => currentIndex !== index
    );

    setEditedPlate({
      ...newEditedPlates,
    });
    setFieldValue('cards', newEditedPlates, true);
  };

  if (!editedCards) {
    return 'loading...';
  }

  return (
    <div>
      <div>
        <p className="formSectionTitle2">{text('contract-page-form-label-cards')}</p>
      </div>
      <div style={{ width: '450px' }} />
      <div className="containerCredentialForm">
        <div className="accountInputContract2" style={{ width: 'calc(100% - 50px)' }}>
          <p className="formSectionTitle3">{text('contract-page-form-label-cards-type')}</p>
        </div>
        <div className="accountInputContract2">
          <p className="formSectionTitle3">{text('contract-page-form-label-cards-number')}</p>
        </div>
      </div>
      {cards.map(({ type, value, provider }, index) =>
        type === 'PROVIDER_EXTERNAL_ID' ? (
          <div key={`${index + value}`} className="containerCredentialForm">
            <div className="select-wrapper" id="label-for-1">
              <select
                className="form-control"
                id="1"
                value={editedCards[index].provider ?? ''}
                onBlur={onBlurChange}
                disabled={readOnly}
                onChange={(evt) =>
                  setEditedPlate({
                    ...editedCards,
                    [index]: { type, value, provider: evt.target.value },
                  })
                }
              >
                {['FAKE', 'TCS', 'SUISSE-PASS', 'SKIDATA', 'TCSOSP'].map((option) => (
                  <option key={option} value={option}>
                    {useProvider[option]}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ width: '25px', marginTop: '45px' }} />
            <input
              className="accountInputContract form-control form-control-sm"
              readOnly={readOnly}
              disabled={readOnly}
              onBlur={onBlurChange}
              onChange={(evt) =>
                setEditedPlate({
                  ...editedCards,
                  [index]: { type, value: evt.target.value, provider },
                })
              }
              value={editedCards[index].value}
            />
            <div style={{ width: '25px', marginTop: '45px' }} />
            {!readOnly ? (
              <div
                onClick={() => supCredential(index)}
                aria-hidden="true"
                style={{ width: '30px', cursor: 'pointer' }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                  <path d="M0 0h24v24H0z" fill="none" />
                </svg>
              </div>
            ) : null}
          </div>
        ) : null
      )}
    </div>
  );
}

function Defile({ label, value, color }) {
  return (
    <>
      <div className="cardContract">
        <div className="cardContractLabel">
          <p className="subLabelContract">{label}</p>
        </div>
        <div className="cardContractValue">
          <p className="subLabelContract" style={{ color }}>
            {value}
          </p>
        </div>
      </div>
      <div
        className="divider"
        style={{ width: 'calc(100% - 150px)', borderBottom: '1px solid #ababab20' }}
      />
    </>
  );
}

const interpretStatus = ({ startDate, stopDate, isEnabled, _id }) => {
  // isEnabled = determines that the contract was activated by the operator
  // isWithin =  determines whether the contract is active on a time basis, i.e. currently usable in a parking lot
  // isExpired = detects if the contract has expired, i.e. it has been used and is now over.

  // const isWithin = isWithinInterval(new Date(), { start: new Date(startDate), end: new Date(stopDate) });

  if (!stopDate) return { state: 'Error', color: 'ERROR' };

  const statusSelector = [
    {
      state: 'contracts-page-table-label-isEnabled-status-inactive',
      condition: !isEnabled,
      color: 'red',
    },
    {
      state: 'contracts-page-table-label-isEnabled-status-active',
      condition: !!isEnabled,
      color: 'black',
    },
    {
      state: 'contracts-page-table-label-isEnabled-status-expired',
      condition: isAfter(new Date(), new Date(stopDate)),
      color: 'red',
    },
  ];

  return statusSelector.filter(({ condition }) => condition).pop();
};

export default function Home() {
  const text = useDictionaries();
  const { id } = useParams();

  const { loading, error, data } = useQuery(GET_CONTRACT, {
    variables: { contractId: id },
  });

  // console.log({ id, data });

  const navigate = useNavigate();
  const formRef = useRef();
  const [submitZoneState, setSubmitZoneState] = useState('idle');

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
      setSubmitZoneState('idle');
    }
  };

  const {
    _id = '',
    ospContractId = '',
    parking = { name: '' },
    startDate,
    stopDate,
    isEnabled,
    category = '',
    credentials = [],
  } = {
    ...data?.contract,
  };

  const credentialsData = useMemo(
    () => ({
      contractId: _id,
      plates: filterPlates(credentials ?? []),
      cards: filterCards(credentials ?? []),
    }),
    [credentials]
  );

  const { state = 'Error', color } = interpretStatus({
    startDate,
    stopDate,
    isEnabled,
    _id,
  });

  return (
    <Layout>
      <div className="root">
        <div className="backButtonContainer">
          <div
            className="backButton"
            role="button"
            onKeyPress={() => {}}
            tabIndex="0"
            onClick={() => navigate('/contracts')}
          >
            <h5 style={{ textTransform: 'capitalize' }}>{`${text(
              'contract-page-backButton-to-contracts'
            )} ←`}</h5>
          </div>
        </div>
        <div className="titleMenu">{`${text('contract-page-label-contract')}`}</div>
        {loading ? (
          <div className="boxContainerMotorist">
            <div className="formMotoristContainer">
              <div className="submitZoneLeft">loading...</div>
            </div>
          </div>
        ) : (
          <div className="boxContainerMotorist">
            <div className="formMotoristContainer">
              <div className="submitZoneLeft">
                <p className="titleParkingName">{text(`parking-${parking._id}-name`)}</p>
                <div className="divider" style={{ width: 'calc(100% - 150px)' }} />
                <br />
                <Defile label={text('contract-page-label-contract-nb')} value={ospContractId} />
                <Defile label={text('contracts-page-label-category')} value={category} />
                <Defile
                  label={text('contract-page-label-startDate')}
                  value={format(new Date(startDate), 'dd.MM.yyyy')}
                />
                <Defile
                  label={text('contract-page-label-stopDate')}
                  value={format(new Date(stopDate), 'dd.MM.yyyy')}
                />
                <Defile
                  label={text('contract-page-label-status')}
                  value={text(state)}
                  color={color}
                />
                <br />
                <div className="divider" style={{ width: 'calc(100% - 150px)' }} />

                {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}

                {credentialsData ? (
                  <GqlEdit
                    id="_id"
                    gql={UPDATE_CONTRACT}
                    data={credentialsData}
                    buttonLabel="Create"
                    onSuccess={(evt) => console.log(evt)}
                    style={{ with: '100%', background: 'red' }}
                    formRef={formRef}
                    submitButtonProps={{
                      style: { display: 'none' },
                    }}
                  >
                    <div className="formMotorist" style={{ width: '100%' }}>
                      <Field
                        readOnly={submitZoneState !== 'editing'}
                        handleSubmit={handleSubmit}
                        type="custom"
                        name="plates"
                        label=""
                        className="accountInputContract form-control form-control-sm"
                        style={{ width: '600px' }}
                        custom={Plate}
                      />
                      <Field
                        readOnly={submitZoneState !== 'editing'}
                        handleSubmit={handleSubmit}
                        type="custom"
                        name="cards"
                        label=""
                        className="accountInputContract form-control form-control-sm"
                        style={{ width: '600px' }}
                        custom={Cards}
                      />
                      <Field
                        readOnly={submitZoneState !== 'editing'}
                        handleSubmit={handleSubmit}
                        type="custom"
                        name="cards"
                        label=""
                        className="accountInputContract form-control form-control-sm"
                        style={{ width: '600px' }}
                        custom={Qrcode}
                      />
                    </div>
                  </GqlEdit>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}
