import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { useSessionStorageString } from 'react-use-window-sessionstorage';
import { useDictionaries, useLangSelector } from '../hooks/useTraductor';
import { updateMotorist } from '../reactiveVariables';
import LogoSvg from './logo_osp.svg';
import SIGNING from './signin.gql';
import Logo from './logo';

import './style.css';

function LangSelector(params) {
  const { setLang, lang, languages } = useLangSelector();

  const handleChangeLang = (e) => {
    console.log('change lang in selector');
    setLang(e.target.value);
  };

  return (
    <div>
      <select
        name="cars"
        id="cars"
        value={lang ?? 'en_EN'}
        onChange={handleChangeLang}
        className="languagesSelector"
      >
        {languages.map((codeLang) => (
          <option value={codeLang} key={codeLang}>
            {`${codeLang}`.slice(0, 2).toUpperCase()}
          </option>
        ))}
      </select>
    </div>
  );
}

function LoginForm({ onSubmit, token }) {
  const text = useDictionaries();

  const handleLogout = () => {
    console.log('handleLogout');
    onSubmit('');
  };

  if (token !== '') {
    return (
      <div className="langSelectorContainer">
        <LangSelector />
        <div>
          <button type="button" className="btn btn-danger" onClick={handleLogout}>
            {text('header-right-logout-button')}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="langSelectorContainer">
      <LangSelector />
    </div>
  );
}

function Layout({ children }) {
  const [token, setToken] = useSessionStorageString('session-token', '');

  const handleSubmit = (data) => {
    updateMotorist({
      logued: false,
      username: '',
      firstName: '',
      lastName: '',
      credentials: [],
      address1: '',
      address2: '',
      zipcode: '',
      city: '',
      country: '',
      phone: '',
      lang: undefined,
    });
    setToken(data);
  };

  return (
    <div>
      <div className="headerBar">
        <div className="containerLogo">
          <img src={LogoSvg} style={{ height: 35 }} alt="website logo" />
        </div>
        <LoginForm onSubmit={handleSubmit} token={token} />
      </div>
      <div className="rootContainer">{children}</div>
    </div>
  );
}

export default Layout;
