import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

export default function Paging({ paging: { count, current, limit }, onPageChange }) {
  const pagination = [];
  let start = current > 3 ? current - 3 : 1;
  const total = Math.ceil(count / limit);
  const end = current < total - 3 ? current + 3 : total;

  if (current > 1) {
    pagination.push(
      <PaginationItem key="back">
        <PaginationLink previous onClick={onPageChange(current - 1)}></PaginationLink>
      </PaginationItem>
    );
  }

  if (current - 3 > 1) {
    pagination.push(
      <PaginationItem key={1} active={current === 1}>
        <PaginationLink onClick={onPageChange(1)}>{1}</PaginationLink>
      </PaginationItem>
    );
    pagination.push(
      <PaginationItem key="pagination-less" disabled={true}>
        <PaginationLink>...</PaginationLink>
      </PaginationItem>
    );
  }

  while (start <= end) {
    pagination.push(
      <PaginationItem key={start} active={start === current}>
        <PaginationLink onClick={onPageChange(start)}>{start++}</PaginationLink>
      </PaginationItem>
    );
  }

  if (current + 3 < total) {
    pagination.push(
      <PaginationItem key="pagination-more" disabled={true}>
        <PaginationLink>...</PaginationLink>
      </PaginationItem>
    );
    pagination.push(
      <PaginationItem key={total} active={current === total}>
        <PaginationLink onClick={onPageChange(total)}>{total}</PaginationLink>
      </PaginationItem>
    );
  }

  if (current < total) {
    pagination.push(
      <PaginationItem key="next">
        <PaginationLink next onClick={onPageChange(current + 1)}></PaginationLink>
      </PaginationItem>
    );
  }

  return <Pagination className="d-flex justify-content-center">{pagination}</Pagination>;
}
