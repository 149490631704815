import React from 'react';

export default function TableListField({ name, value, data, ...props }) {
  return <td>{formatValue(name, value, data, props)}</td>;
}

// Affiche une valeur du tableau
function formatValue(name, value, data, props) {
  if (props.component) return <props.component name={name} value={props.decorator ? props.decorator(value, data) : value} data={data} {...props} />;
  else if (props.decorator)
    return <div className={props.className}>{props.decorator(value, data)}</div>;
  else return value;
}
